<template>
  <validation-observer ref="validator">
    <b-row>
      <b-col cols="12" md="3">
        <validation-provider
          #default="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-group>
            <label for="role-name">角色名称</label>
            <b-form-input
              id="role-name"
              v-model="role.name"
              placeholder="角色名称"
              :disabled="role.type == 0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="12" class="mt-1">
        <b-form-group>
          <label for="role-right">权限选择</label>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table-simple>
          <b-tr> <b-th>资源</b-th> <b-th>权限</b-th></b-tr>
          <b-tr v-for="(resource, rIdx) in resources" :key="rIdx">
            <b-td> {{ resource.name }} </b-td>
            <b-td>
              <b-form-radio-group
                :options="rightOptions"
                v-model="resource.right"
              >
              </b-form-radio-group>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>

      <b-col cols="12" class="text-right">
        <b-button @click="save" variant="primary" class="mr-1"
          >保存权限</b-button
        >
        <b-button @click="abortAndReturn">返回</b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BTableSimple,
  BTh,
  BTr,
  BTd,
  BFormRadioGroup,
} from "bootstrap-vue";

import { isNumber } from "@vueuse/shared";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, password } from "@validations";

export default {
  name: "RoleEdit",

  emits: ["save"],

  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BTableSimple,
    BTh,
    BTr,
    BTd,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      role: {
        id: 0,
        eid: 1,
        name: "新建角色",
        type: 1,  // 0: 系统角色，1: 用户角色
        ability: null,
        status: 1,
      },
      resources: null,

      rightOptions: [
        { text: "无权限", value: "" },
        { text: "只读", value: "read" },
        { text: "只写", value: "write" },
        { text: "读写", value: "manage" },
      ],

      currentRight: null,
    };
  },

  beforeCreate() {
    this.$http.get("/resources", { eid: 1 }).then((res) => {
      this.resources = res.data.data.list;
      this.abilityToResource();
    });

    let id = this.$route.params.id;

    if (id != "new") {
      this.$http.get("/roles/" + id).then((res) => {
        this.role = res.data.data;
        this.abilityToResource();

        // move the permissions to the role.ability
        // this.$http.get("/permissions?roleId=" + id).then((res) => {
        //   this.permissions = res.data.data.list;
        //   this.preparePermissions();
        // });
      });
    }
  },

  methods: {
    // copy the permissions from ablitity to resource.right
    abilityToResource() {
      if (!this.resources || !this.role) return;

      // init the resource.right
      this.resources.forEach((resource) => {
        this.$set(resource, "right", '');
      });

      // ablitity format is
      // [
      //   {
      //     action: "read|wirte|manage",
      //     subject: ["name1", "name2"]
      //   },
      //   ...
      // ];

      let abilities = JSON.parse(this.role.ability);
      if (!abilities) return;

      let actions = ["read", "write", "manage"];

      actions.forEach((action) => {
        let ability = abilities.find((item) => item.action == action);
        if (!ability) return;

        let subjects = ability.subject;
        if (!subjects) return;

        subjects.forEach((subject) => {
          let resource = this.resources.find((item) => item.name == subject);
          if (resource) {
            resource.right = action;
          }
        });
      });
    },

    // copy the permissions from resource.right to ablitity object
    resourceToAbility() {
      if (!this.resources || !this.role) return;

      let abilities = [];

      this.resources.forEach((resource) => {
        let right = resource.right;
        if (!right) return;

        let ability = abilities.find((item) => item.action == right);

        if (!ability) {
          ability = {
            action: right,
            subject: [],
          };
          abilities.push(ability);
        }

        ability.subject.push(resource.name);
      });

      this.role.ability = JSON.stringify(abilities);
      // this.role.ability = abilities;
    },

    save() {
      // parepare the role.ability
      this.resourceToAbility();

      this.$refs.validator.validate("name").then((valid) => {
        if (valid) {
          // 新建角色
          if (!this.role.id) {
            this.$http.post("/roles", this.role).then((res) => {
              this.role = res.data.data;
              this.$swal("保存成功", "", "success");
            });
          } else {
            // 更新角色名称
            this.$http.put("/roles/" + this.role.id, this.role).then((res) => {
              this.role = res.data.data;
              this.$swal("保存成功", "", "success");
            });
          }
        }
      });
    },

    abortAndReturn() {
      this.$router.push({
        name: "role",
      });
    },

    // // init permissions from resources & existing permissions
    // preparePermissions() {
    //   if (!this.resources || !this.permissions) {
    //     return;
    //   }

    //   this.resources.forEach((resource) => {
    //     let permission = this.permissions.find(
    //       (p) => p.resourceId == resource.id
    //     );

    //     // if no permission found, create a new one
    //     if (!permission) {
    //       permission = {
    //         id: 0,
    //         eid: 1,
    //         roleId: this.role.id,
    //         resourceId: resource.id,
    //         right: 0,
    //         status: 1,
    //       };
    //       this.permissions.push(permission);
    //     }

    //     // add resouce to permission, just for display
    //     permission.resourceName = resource.name;
    //   });
    // },

    abortAndReturn() {
      this.$router.push({
        name: "role",
      });
    },

    // // save name
    // saveName() {
    //   this.$refs.validator.validate("name").then((valid) => {
    //     if (valid) {
    //       // 新建角色
    //       if (!this.role.id) {
    //         this.$http.post("/roles", this.role).then((res) => {
    //           this.role = res.data.data;
    //           this.$swal("保存成功", "", "success");
    //         });
    //       } else {
    //         // 更新角色名称
    //         this.$http.put("/roles/" + this.role.id, this.role).then((res) => {
    //           this.role = res.data.data;
    //           this.$swal("保存成功", "", "success");
    //         });
    //       }
    //     }
    //   });
    // },

    // savePermissions() {
    //   //save to server
    //   this.$http.post("/permissions", this.permissions).then((res) => {
    //     // console.log(res);
    //     this.$swal("保存成功", "", "success");
    //   });
    // },
  },
};
</script>